import React, { useState, Fragment, useRef } from "react";
import { createContext } from "react";
import { Toast } from "primereact/toast";
import "../assets/css/toast.css";

const AlertContext = createContext();
const { Provider, Consumer } = AlertContext;

function AlertProvider({ children }) {
  const [summary, setSummary] = useState(null);
  const [detail, setDetail] = useState(null);
  const [severity, setSeverity] = useState(null);
  const toast = useRef(null);

  const mensajeAlerta = (summary, detail, severity) => {
    toast.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    });
  };

  const mensajeAlerta2 = (summary, detail, severity) => {
    toast.current.show({
      severity,
      summary,
      detail,
      sticky: true,
    });
  };

  const cerrarAlerta = () => {
    toast.current.clear();
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <Provider
        value={{
          mensajeAlerta,
          mensajeAlerta2,
          cerrarAlerta,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { AlertProvider, Consumer as AlertConsumer, AlertContext };
